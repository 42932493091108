import { Button } from '@askable/ui/core/button';
import { Skeleton } from '@askable/ui/core/skeleton';
import { format, formatISO } from 'date-fns';
import { Sparkles } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from 'urql';

import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { CreateBookingTaskResultOpenAnswerSummary } from 'containers/Studies/Results/data/CreateBookingTaskResultOpenAnswerSummary.mutation';
import { BookingTaskResultDetailsType } from 'generated/graphql';

interface OpenAnswerResultsProps {
  taskId: string;
  title: string;
  totalCount: number;
}

interface SummaryData {
  created: Date;
  response_count: number;
  summary: string;
}

const IS_SUMMARY_ENABLED = false;

export const OpenAnswerResults = ({ taskId, title, totalCount }: OpenAnswerResultsProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { studyId } = params;
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);

  const [{ data, error, fetching }, createBookingTaskResultOpenAnswerSummary] = useMutation(
    CreateBookingTaskResultOpenAnswerSummary,
  );

  const getStorageKey = () => (studyId && taskId ? `${studyId}_open_answer_summary_${taskId}` : null);

  // Load summary data from localStorage. Will be replaced with backend data fetching in ASK-10907
  useEffect(() => {
    const key = getStorageKey();
    if (!key) {
      return;
    }

    try {
      const storedSummary = localStorage.getItem(key);

      if (storedSummary) {
        const parsed = JSON.parse(storedSummary);
        parsed.created = new Date(parsed.created);
        setSummaryData(parsed);
      } else {
        setSummaryData(null);
      }
    } catch (e) {
      console.error('Error loading from localStorage', e);
      localStorage.removeItem(key);
    }
  }, [studyId, taskId]);

  // Update localStorage when new summary is generated
  useEffect(() => {
    const summary = data?.createBookingTaskResultOpenAnswerSummary;
    const key = getStorageKey();

    if (summary && summary._task_id === taskId && key) {
      const newSummary = {
        created: new Date(summary.created),
        response_count: summary.response_count,
        summary: summary.summary,
      };

      localStorage.setItem(
        key,
        JSON.stringify({
          ...newSummary,
          created: newSummary.created.toISOString(),
        }),
      );

      setSummaryData(newSummary);
    }
  }, [data, taskId, studyId]);

  const handleGenerateSummary = async () => {
    if (!studyId || !taskId) {
      return;
    }

    try {
      await createBookingTaskResultOpenAnswerSummary({
        input: {
          _booking_id: { eq: studyId },
          _task_id: { eq: taskId },
        },
      });
    } catch (err) {
      console.error('Error generating summary:', err);
    }
  };

  return (
    <>
      <TaskHeader responses={totalCount} title={title} type={BookingTaskResultDetailsType.OpenAnswer} />

      {/* @todo ASK-10907 - improve summary view and fetch from backend. Hidden via IS_SUMMARY_ENABLED flag */}
      {IS_SUMMARY_ENABLED ? (
        <section className="flex flex-col gap-4 rounded-md border-0.5 border-border p-4">
          <div className="flex flex-wrap justify-between gap-2">
            <div className="flex flex-col">
              <h2 className="text-lg font-semibold">{t('sections.studies.results.openAnswer.summary')}</h2>
              <div className="text-sm text-muted-foreground">
                {t('sections.studies.results.openAnswer.summaryDescription')}
              </div>
            </div>
            <Button variant="outline" onClick={handleGenerateSummary} isLoading={fetching}>
              <Sparkles className="h-4 w-4" /> {t('sections.studies.results.openAnswer.generateSummary')}
            </Button>
          </div>

          {error ? <div className="text-destructive">{error.message}</div> : null}

          {!fetching && summaryData ? (
            <>
              <div className="whitespace-pre-wrap text-pretty">{summaryData.summary}</div>

              <div className="text-sm text-muted-foreground">
                {t('sections.studies.results.openAnswer.created')}:{' '}
                <time dateTime={formatISO(summaryData.created)} title={formatISO(summaryData.created)}>
                  {format(summaryData.created, 'd MMM, h:mmaaa')}
                </time>
              </div>
            </>
          ) : null}

          {fetching ? (
            <>
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-3/5" />
            </>
          ) : null}
        </section>
      ) : null}
    </>
  );
};
