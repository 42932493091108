import { Skeleton } from '@askable/ui/core/skeleton';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { FigmaPrototypeResults } from 'containers/Studies/Results/components/Task/FigmaPrototype/FigmaPrototypeResults';
import { IndividualResponses } from 'containers/Studies/Results/components/Task/IndividualResponses';
import { MultipleChoiceQuestionResults } from 'containers/Studies/Results/components/Task/MultipleChoiceQuestion/MultipleChoiceQuestionResults';
import { OpenAnswerResults } from 'containers/Studies/Results/components/Task/OpenAnswer/OpenAnswerResults';
import { OpinionScaleResults } from 'containers/Studies/Results/components/Task/OpinionScale/OpinionScaleResults';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { BookingTaskResults } from 'containers/Studies/Results/data/BookingTaskResults.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';
import { TaskBlockType } from 'generated/graphql';

import type {
  BookingTaskResultDetailsType,
  TaskBlockMultipleChoiceQuestion,
  TaskBlockOpinionScale,
} from 'generated/graphql';

const PER_PAGE = 10;

export const TaskContent = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { taskId } = params;
  const [cursor, setCursor] = useState<string | null>(null);

  const limit = parseInt(searchParams.get('limit') ?? '', 10);
  const taskBlock = study.config?.unmoderated?.task_blocks?.find(block => block?._id === params.taskId);

  const [{ data, fetching }] = useQuery({
    query: BookingTaskResults,
    variables: {
      first: isNaN(limit) ? PER_PAGE : limit,
      after: cursor ?? undefined,
      filter: {
        _booking_id: { eq: study._id },
        _task_id: { eq: taskId },
        // TODO: we actually want to filter by booking submission status here, but that's not possible / implemented
        // yet, so for now we just filter out started and loaded results
        status: { in: ['completed', 'skipped'] },
      },
    },
    pause: !taskId || !study._id,
  });

  const results = data?.bookingTaskResults.nodes ?? [];

  const handleLoadMore = async () => {
    if (data?.bookingTaskResults.pageInfo.hasNextPage && data?.bookingTaskResults.pageInfo.endCursor) {
      setCursor(data?.bookingTaskResults.pageInfo.endCursor);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: `${t('sections.studies.tabs.results')} - ${taskBlock?.title}`,
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <div
        id={`task-block-${taskBlock?._id}`}
        className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8"
      >
        {taskBlock ? (
          <>
            {fetching && results.length === 0 ? <Skeleton className="h-full w-full" /> : null}

            {!fetching && results.length === 0 ? (
              <TaskHeader
                title={taskBlock.title}
                type={taskBlock.type as unknown as BookingTaskResultDetailsType}
                responses={0}
              />
            ) : null}

            {results.length > 0 ? (
              <>
                {match(taskBlock.type)
                  .with(TaskBlockType.FigmaPrototype, () => (
                    <FigmaPrototypeResults taskId={taskBlock?._id} title={taskBlock.title} />
                  ))
                  .with(TaskBlockType.MultipleChoiceQuestion, () => (
                    <MultipleChoiceQuestionResults
                      taskId={taskBlock?._id}
                      title={taskBlock.title}
                      isMultipleSelect={
                        (taskBlock as TaskBlockMultipleChoiceQuestion)?.multiple_choice_question?.is_multiple_select ??
                        false
                      }
                    />
                  ))
                  .with(TaskBlockType.OpenAnswer, () => (
                    <OpenAnswerResults
                      taskId={taskBlock?._id}
                      title={taskBlock.title}
                      totalCount={data?.bookingTaskResults.totalCount ?? 0}
                    />
                  ))
                  .with(TaskBlockType.OpinionScale, () => (
                    <OpinionScaleResults
                      labels={[
                        (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_low ?? '',
                        (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_mid ?? '',
                        (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_high ?? '',
                      ]}
                      taskId={taskBlock?._id}
                      title={taskBlock.title}
                    />
                  ))
                  .otherwise(() => null)}

                <IndividualResponses
                  isLoading={fetching && cursor !== null}
                  perPage={limit}
                  results={results}
                  totalCount={data?.bookingTaskResults.totalCount ?? 0}
                  type={taskBlock.type}
                  onLoadMore={handleLoadMore}
                />
              </>
            ) : (
              <EmptyState />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
