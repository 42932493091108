import { Button } from '@askable/ui/core/button';
import { Checkbox } from '@askable/ui/core/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@askable/ui/core/dropdown-menu';
import { Download, ChevronDown, Flame, MousePointerClick } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'containers/Studies/Results/components/Tabs';
import { getOrdinalNumber } from 'containers/Studies/utils/utils';

import type { HeatmapView } from 'containers/Studies/Results/components/Heatmap/Heatmap';

interface ClickOption {
  title: string;
  value: number | null;
}

interface HeatmapHeaderProps {
  activeClicks: number[];
  clicksCount: number;
  title?: string;
  view: HeatmapView;
  onActiveClicksChange: (indexes: number[]) => void;
  onDownload: () => void;
  onViewChange: (view: HeatmapView) => void;
}

export const HeatmapHeader = ({
  activeClicks,
  clicksCount,
  view,
  onActiveClicksChange,
  onDownload,
  onViewChange,
}: HeatmapHeaderProps) => {
  const { t } = useTranslation();

  const clickOptions: ClickOption[] = Array.from({ length: clicksCount }, (_, i) => ({
    title: `${getOrdinalNumber(i + 1)} ${t('sections.studies.results.clicks', { count: 1 })}`,
    value: i,
  }));

  clickOptions.unshift({
    title: t('sections.studies.results.click.all'),
    value: null,
  });

  const tabs = [
    {
      label: t('sections.studies.results.heatMaps', { count: 1 }),
      icon: Flame,
      value: 'heatmap',
    },
    {
      label: t('sections.studies.results.clickMap'),
      icon: MousePointerClick,
      value: 'clickmap',
    },
  ];

  const handleSelectClickItem = (value: number | null) => {
    if (value === null) {
      onActiveClicksChange([]);
      return;
    }

    // If the option is already in the activeClicks array, remove it
    if (activeClicks.includes(value)) {
      onActiveClicksChange(activeClicks.filter(click => click !== value));
      return;
    }

    onActiveClicksChange([...activeClicks, value]);
  };

  return (
    <div className="flex flex-wrap items-center justify-between gap-2 px-4 py-2 text-sm">
      <div className="flex items-center gap-2">
        <Tabs value={view} tabs={tabs} variant="icons" onValueChange={value => onViewChange(value as HeatmapView)} />

        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="pr-2"
              title={t('sections.studies.options', { count: 2 })}
              disabled={clicksCount <= 1}
            >
              {activeClicks.length > 0
                ? `${activeClicks.length} ${t('sections.studies.results.clicks', { count: activeClicks.length }).toLowerCase()}`
                : t('sections.studies.results.click.all')}
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="z-[2101]" align="start">
            {clickOptions.map(option => (
              <DropdownMenuItem
                key={option.value}
                onClick={() => handleSelectClickItem(option.value)}
                onSelect={event => event.preventDefault()}
              >
                {option.value === null ? (
                  <Checkbox checked={activeClicks.length === 0 ? true : 'indeterminate'} />
                ) : (
                  <Checkbox checked={activeClicks.includes(option.value ?? 0)} />
                )}
                {option.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2 md:justify-end">
        <Button variant="outline" size="icon" title={t('global.download')} onClick={onDownload}>
          <Download className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};
