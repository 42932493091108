import { useState } from 'react';

import { cn } from '../lib/utils';

export type ScaleType = 'numerical' | 'stars' | 'emotions';

interface OpinionScaleProps {
  isZeroStart?: boolean;
  labelHigh?: string;
  labelLow?: string;
  labelMid?: string;
  max: number;
  optionLabel?: string;
  type: ScaleType;
  onChange: (value: number) => void;
}

interface OpinionScaleResultProps {
  value: number;
  max: number;
  size?: 'default' | 'large';
  type: ScaleType;
}

export const emotionsScaleMap = {
  5: [1, 2, 5, 7, 9],
  6: [1, 2, 3, 6, 7, 9],
  7: [1, 2, 3, 5, 6, 7, 9],
  8: [1, 2, 3, 4, 5, 6, 7, 9],
  9: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
};

export const emotionColors = [
  'bg-color-red',
  'bg-color-red',
  'bg-color-orange',
  'bg-color-orange',
  'bg-color-yellow',
  'bg-color-yellow',
  'bg-color-lime',
  'bg-color-lime',
  'bg-color-green',
  'bg-color-green',
];

const getSafeMax = (max: number) => (max < 3 || max > 10 ? 5 : (max as keyof typeof emotionsScaleMap));

const emotions: { class: string; icon: string }[] = [
  {
    class: 'bg-color-red/20 border-color-red peer-checked:bg-color-red peer-hover:bg-color-red',
    icon: '<circle cx="12.5" cy="14" r="1.8" fill="currentColor"/><circle cx="21" cy="14" r="1.8" fill="currentColor"/><rect width="7.2" height="2.3" x="9.5" y="8.9" fill="currentColor" rx="1.1" transform="rotate(29 9.5 8.9)"/><rect width="7.2" height="2.3" x="18" y="12.4" fill="currentColor" rx="1.1" transform="rotate(-29 18 12.4)"/><rect width="14" height="2.7" x="9.8" y="23.2" fill="currentColor" rx="1.4"/><path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M11 24.5c0-2.5 3-4.7 6-4.7s5.5 2.2 5.5 4.7"/>',
  },
  {
    class: 'bg-color-red/20 border-color-red peer-checked:bg-color-red peer-hover:bg-color-red',
    icon: '<circle cx="12.5" cy="14" r="1.8" fill="currentColor"/><circle cx="21" cy="14" r="1.8" fill="currentColor"/><rect width="7.2" height="2.3" x="9.5" y="8.9" fill="currentColor" rx="1.1" transform="rotate(29 9.5 8.9)"/><rect width="7.2" height="2.3" x="18" y="12.4" fill="currentColor" rx="1.1" transform="rotate(-29 18 12.4)"/><path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M11 24c1-2 4-3 6-3s5 1 6 3"/>',
  },
  {
    class: 'bg-color-orange/20 border-color-orange peer-checked:bg-color-orange peer-hover:bg-color-orange',
    icon: '<path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M11 24c1-2 4-3 6-3s5 1 6 3"/>',
  },
  {
    class: 'bg-color-orange/20 border-color-orange peer-checked:bg-color-orange peer-hover:bg-color-orange',
    icon: '<path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M12 23.5c1-1 2.5-1.8 4.5-1.8s3.5.8 4.5 1.8"/>',
  },
  {
    class: 'bg-color-yellow/20 border-color-yellow peer-checked:bg-color-yellow peer-hover:bg-color-yellow',
    icon: '<rect width="12.8" height="2.7" x="9.8" y="21.8" fill="currentColor" rx="1.4" transform="rotate(-10 9.8 21.8)"/>',
  },
  {
    class: 'bg-color-yellow/20 border-color-yellow peer-checked:bg-color-yellow peer-hover:bg-color-yellow',
    icon: '<rect width="15.6" height="2.7" x="9.2" y="20.6" fill="currentColor" rx="1.4"/>',
  },
  {
    class: 'bg-color-lime/20 border-color-lime peer-checked:bg-color-lime peer-hover:bg-color-lime',
    icon: '<path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M12.5 22.5c1 1 2.5 1.5 4.5 1.5s3.5-.5 4.5-1.5"/>',
  },
  {
    class: 'bg-color-lime/20 border-color-lime peer-checked:bg-color-lime peer-hover:bg-color-lime',
    icon: '<path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M10 21c1 1.5 3.1 3.2 6.5 3.2S22 22.5 23 21"/>',
  },
  {
    class: 'bg-color-green/20 border-color-green peer-checked:bg-color-green peer-hover:bg-color-green',
    icon: '<path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M8.4 20.5c.5 3 4.5 5.5 8.5 5.5s7.5-2.5 8-6"/>',
  },
  {
    class: 'bg-color-green/20 border-color-green peer-checked:bg-color-green peer-hover:bg-color-green',
    icon: '<rect width="17.8" height="2.7" x="8" y="19.2" fill="currentColor" rx="1.4" transform="rotate(-4 8 19.2)"/><path stroke="currentColor" stroke-linecap="round" stroke-width="2.7" d="M9 20.5c.5 3 4 6 8 6s7-3.5 7.5-7" />',
  },
];

export const Emotion = ({ className, typeIndex = 0 }: { className?: string; typeIndex?: number }) => {
  if (typeIndex < 0 || typeIndex >= emotions.length) {
    typeIndex = 0;
  }

  return (
    <svg fill="none" viewBox="0 0 36 36" className={cn('h-6 @[46px]:h-8', className)}>
      <path fill="currentColor" d="M35 19c0 8.5-5.8 15-15.5 15S2 25.8 2 17.5 7.3 2 17 2s18 8.5 18 17Z" />
      <ellipse cx="17" cy="17.5" fill="#fff" rx="14" ry="14.5" />
      <g dangerouslySetInnerHTML={{ __html: emotions[typeIndex].icon }} />
      {typeIndex > 1 ? (
        <>
          <circle cx="12.5" cy="12.8" r="1.8" fill="currentColor" />
          <circle cx="21" cy="12.8" r="1.8" fill="currentColor" />
        </>
      ) : null}
    </svg>
  );
};

const Star = () => {
  return (
    <svg fill="none" viewBox="0 0 36 36" className="h-6 @[46px]:h-8">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 2c.6 0 1.1.3 1.4.8l4.4 8.9 9.9 1.4c.6 0 1 .5 1.2 1 .2.6 0 1.2-.4 1.6l-7.1 6.8 1.7 9.7c0 .6-.2 1.2-.6 1.5-.5.3-1.1.4-1.7.1L18 29.3l-8.8 4.5a2 2 0 0 1-1.7 0c-.4-.4-.7-1-.6-1.6l1.7-9.7-7.1-6.8c-.5-.4-.6-1-.4-1.6.2-.5.6-1 1.2-1l9.9-1.4 4.4-8.9c.3-.5.8-.8 1.4-.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const OpinionScaleResult = ({ value, max, size = 'default', type }: OpinionScaleResultProps) => {
  return (
    <div className={cn('flex items-center gap-2', { 'text-3xl font-semibold': size === 'large' })}>
      {type === 'stars' ? (
        <div className={cn('flex items-center justify-center text-color-yellow', { 'h-7 w-7': size === 'large' })}>
          <Star />
        </div>
      ) : null}

      {type === 'emotions' ? (
        <div
          className={cn(
            `${emotionColors[Math.max(0, Math.min(Math.floor(value), emotionColors.length - 1))]} flex aspect-square h-7 w-7
            items-center justify-center rounded-full`,
          )}
        >
          <Emotion typeIndex={emotionsScaleMap[getSafeMax(max)][Math.max(0, Math.floor(value) - 1)]} />
        </div>
      ) : null}

      <div
        className={cn('flex flex-row items-center gap-1 text-muted-foreground', {
          'items-end': size === 'large',
        })}
      >
        <span className={cn('text-primary', { 'text-3xl font-semibold leading-none': size === 'large' })}>
          {parseFloat(value.toFixed(2))}
        </span>
        <span className={cn('', { 'text-sm': size === 'large' })}>/ {max}</span>
      </div>
    </div>
  );
};

export const OpinionScale = ({
  isZeroStart,
  labelHigh,
  labelLow,
  labelMid,
  max,
  optionLabel = 'Option',
  type = 'numerical',
  onChange,
}: OpinionScaleProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const safeMax = getSafeMax(max);
  const emotionsIndexes = emotionsScaleMap[safeMax];

  // Include a zero value for numerical scales
  const itemCount = type === 'numerical' && isZeroStart ? safeMax + 1 : safeMax;

  const handleChange = (value: number) => {
    setSelectedIndex(value);
    onChange(value);
  };

  // Acessibility issue: tap targets can be narrower than WCAG recommended 44px
  return (
    <div className={cn('flex flex-col gap-2 overflow-auto p-[1px] text-sm text-foreground @container')}>
      <ul className="flex gap-1 @[460px]:gap-2">
        {Array.from({ length: itemCount }).map((_, i) => {
          const activeValue = type === 'numerical' && isZeroStart ? i : i + 1;
          const isActive = activeValue === selectedIndex;
          const shouldHighlightStar =
            type === 'stars' &&
            ((hoverIndex !== null && activeValue <= hoverIndex) ||
              (hoverIndex === null && selectedIndex !== null && activeValue <= selectedIndex));

          return (
            <li key={i} className="flex-1 @container">
              <input
                type="radio"
                id={`opinion-scale-${activeValue}`}
                name="opinion-scale"
                className="peer sr-only"
                aria-label={`${optionLabel} ${activeValue}`}
                value={activeValue}
                checked={activeValue === selectedIndex}
                onChange={() => handleChange(activeValue)}
              />

              <label
                htmlFor={`opinion-scale-${activeValue}`}
                className={cn(
                  `flex w-auto origin-center cursor-pointer items-center justify-center rounded-sm border-0.5 border-border px-1 py-4
                  text-lg ring-primary transition-all active:scale-95 peer-focus:ring-1 @[46px]:rounded-lg`,
                  {
                    ['peer-hover:bg-secondary peer-focus:bg-secondary peer-active:bg-border']: type === 'numerical',
                    ['border-primary bg-transparent ring-1 peer-hover:border-primary peer-focus:border-primary peer-active:border-primary']:
                      type === 'numerical' && isActive,
                    [`border-0.5 border-border text-muted-foreground dark:text-background ${emotions[emotionsIndexes[i]]?.class ?? ''}`]:
                      type === 'emotions',
                    ['border-foreground ring-1']: type === 'emotions' && isActive,
                    [`text-border focus:text-color-yellow peer-hover:border-accent-5 peer-hover:bg-yellow-50 peer-hover:text-color-yellow
                    peer-focus:ring-1 peer-focus:ring-border peer-active:bg-yellow-50`]: type === 'stars',
                    ['border-color-yellow bg-yellow-50 text-color-yellow peer-focus:ring-accent-5']:
                      type === 'stars' && shouldHighlightStar,
                  },
                )}
                onMouseEnter={() => type === 'stars' && setHoverIndex(i)}
                onMouseLeave={() => type === 'stars' && setHoverIndex(null)}
              >
                {type === 'numerical' ? (
                  <span className="text-lg font-semibold leading-6 @[46px]:text-xl @[46px]:leading-8">
                    {activeValue}
                  </span>
                ) : null}
                {type === 'stars' ? <Star /> : null}
                {type === 'emotions' ? (
                  <Emotion typeIndex={emotionsIndexes[i]} className={cn({ 'text-foreground': isActive })} />
                ) : null}
              </label>
            </li>
          );
        })}
      </ul>

      {labelLow !== '' || labelMid !== '' || labelHigh !== '' ? (
        <ul className={cn('flex w-full flex-row justify-between gap-6 text-sm text-muted-foreground')}>
          <li className="flex-1">{labelLow}</li>
          <li className={cn('flex-1 text-center')}>{labelMid}</li>
          <li className={cn('flex-1 text-right')}>{labelHigh}</li>
        </ul>
      ) : null}
    </div>
  );
};
