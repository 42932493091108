import { Tabs as ShadTabs, TabsList, TabsTrigger } from '@askable/ui/core/tabs';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Styled Tabs that syncs active tab state with URL eg. `?tab=success`
 */

type TabVariant = 'default' | 'underline' | 'icons';

interface Tab {
  icon?: React.ElementType;
  isDisabled?: boolean;
  label: string;
  value: string;
}

interface TabsProps {
  isFullWidth?: boolean;
  tabs: Tab[];
  value: string;
  variant?: TabVariant;
  onValueChange: (value: string) => void;
}

export const Tabs = ({ tabs, value, variant = 'default', isFullWidth, onValueChange }: TabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Sync active tab state with URL
  const tabFromUrl = searchParams.get('tab');
  const activeTab = tabs.some(tab => tab.value === tabFromUrl) ? (tabFromUrl as string) : value;

  const handleTabChange = (value: string) => {
    if (tabs.some(tab => tab.value === value)) {
      const currentHash = window.location.hash;

      setSearchParams(
        prev => {
          const newParams = new URLSearchParams(prev);
          newParams.set('tab', value);
          return newParams;
        },
        { replace: true, preventScrollReset: true },
      );

      // Hack to preserve the hash https://github.com/remix-run/react-router/issues/8393#issuecomment-2402660908
      if (currentHash) {
        setTimeout(() => {
          window.location.hash = currentHash;
        }, 0);
      }

      onValueChange(value);
    }
  };

  useEffect(() => {
    if (searchParams.has('tab')) {
      handleTabChange(searchParams.get('tab') || value);
    }
  }, []);

  return (
    <ShadTabs defaultValue={activeTab} value={activeTab} onValueChange={handleTabChange}>
      <TabsList
        className={cn({
          'gap-6 bg-transparent': variant === 'underline',
          'gap-1 bg-border/70': variant === 'icons',
          'w-full': isFullWidth,
        })}
      >
        {tabs.map(tab =>
          variant === 'icons' ? (
            <Tooltip key={tab.value}>
              <TabsTrigger value={tab.value} disabled={tab.isDisabled} className="px-1 py-1">
                <TooltipTrigger asChild>
                  <span>{tab.icon ? <tab.icon className="h-4 w-4" /> : null}</span>
                </TooltipTrigger>
              </TabsTrigger>
              <TooltipContent>{tab.label}</TooltipContent>
            </Tooltip>
          ) : (
            <TabsTrigger
              key={tab.value}
              value={tab.value}
              disabled={tab.isDisabled}
              className={cn({
                [`!m-0 rounded-none border-b-2 border-transparent px-0 py-2 data-[state=active]:border-primary
                data-[state=active]:shadow-none`]: variant === 'underline',
                'flex-1': isFullWidth,
                'gap-1': tab.icon,
              })}
            >
              {tab.icon ? <tab.icon className="h-4 w-4" /> : null}
              {tab.label}
            </TabsTrigger>
          ),
        )}
      </TabsList>
    </ShadTabs>
  );
};
