import { Button } from '@askable/ui/core/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@askable/ui/core/table';
import { useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { Section } from 'containers/Studies/Results/components/Section';

import { FigmaPrototypeResponseTableRow } from './FigmaPrototype/FigmaPrototypeResponseTableRow';
import { MultipleChoiceQuestionResponseTableRow } from './MultipleChoiceQuestion/MultipleChoiceQuestionResponseTableRow';
import { OpenAnswerResponseTableRow } from './OpenAnswer/OpenAnswerResponseTableRow';
import { OpinionScaleResponseTableRow } from './OpinionScale/OpinionScaleResponseTableRow';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';
interface IndividualResponsesProps {
  isLoading: boolean;
  perPage: number;
  results: BookingTaskResultPartial[];
  totalCount: number;
  type: BookingTaskResultPartial['details']['type'];
  onLoadMore: () => void;
}

const FigmaPrototypeTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead className="min-w-40 pl-0">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead>{t('sections.studies.results.outcome')}</TableHead>
      <TableHead>{t('sections.studies.results.duration')}</TableHead>
      <TableHead>{t('sections.studies.results.misclickRate')}</TableHead>
      <TableHead>{t('sections.studies.results.screen.viewed')}</TableHead>
      <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

const MultipleChoiceQuestionTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead className="pl-0">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead className="w-full min-w-40">{t('sections.studies.answers', { count: 1 })}</TableHead>
      <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

const OpinionScaleTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead className="flex-1">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead className="w-1/4">{t('sections.studies.ratings', { count: 1 })}</TableHead>
      <TableHead className="w-1/4">{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

const OpenAnswerTableHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TableHead>{t('sections.studies.results.participants', { count: 1 })}</TableHead>
      <TableHead className="w-full">{t('sections.studies.answers', { count: 1 })}</TableHead>
      <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
    </>
  );
};

export const IndividualResponses = ({
  isLoading,
  perPage,
  results,
  totalCount = 0,
  type,
  onLoadMore,
}: IndividualResponsesProps) => {
  const { t } = useTranslation();
  const lastRowRef = useRef<HTMLTableRowElement>(null);

  const moreCount = totalCount > results.length ? totalCount - results.length : 0;
  const description =
    type === 'figma_prototype' ? 'individualResponsesDescriptionFigmaPrototype' : 'individualResponsesDescription';

  // Scroll to the last row when new results are loaded
  useEffect(() => {
    if (results.length > perPage && lastRowRef.current) {
      lastRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [results.length]);

  return (
    <Section
      description={t(`sections.studies.results.${description}`)}
      id="individual-responses"
      title={t('sections.studies.results.individualResponsesTitle')}
    >
      {results && results.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow className="whitespace-nowrap">
              {match(type)
                .with('figma_prototype', () => <FigmaPrototypeTableHeader />)
                .with('multiple_choice_question', () => <MultipleChoiceQuestionTableHeader />)
                .with('open_answer', () => <OpenAnswerTableHeader />)
                .with('opinion_scale', () => <OpinionScaleTableHeader />)
                .exhaustive()}
            </TableRow>
          </TableHeader>

          <TableBody>
            {results.map((result, index) => (
              <Fragment key={result._id}>
                {match(type)
                  .with('figma_prototype', () => (
                    <FigmaPrototypeResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .with('multiple_choice_question', () => (
                    <MultipleChoiceQuestionResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .with('open_answer', () => (
                    <OpenAnswerResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .with('opinion_scale', () => (
                    <OpinionScaleResponseTableRow
                      result={result}
                      ref={index === results.length - 1 ? lastRowRef : undefined}
                    />
                  ))
                  .exhaustive()}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyState />
      )}

      {moreCount ? (
        <Button variant="ghost" className="w-full" onClick={onLoadMore} isLoading={isLoading}>
          {t('global.plusMore', { count: moreCount })}
        </Button>
      ) : null}
    </Section>
  );
};
