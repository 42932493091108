import { useTranslation } from 'react-i18next';

import { Stat } from 'containers/Studies/Results/components/Stat';

interface HeatmapDetailsProps {
  duration?: number;
  misclickRate?: number;
}

export const HeatmapDetails = ({ duration = 0, misclickRate = 0 }: HeatmapDetailsProps) => {
  const { t } = useTranslation();

  return (
    <aside className="flex flex-col gap-6 border-l-0.5 border-border p-4 lg:w-60">
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-semibold">{t('sections.studies.results.screen.metrics')}</h3>

        <Stat type="misclickRate" value={misclickRate} variant="block" size="small" />
        <Stat type="averageDuration" value={duration} variant="block" size="small" />
      </div>
    </aside>
  );
};
